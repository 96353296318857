import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

interface NavitemProps {
  name: string;
  icon?: React.FC;
  href: string;
}

export function NavItem({ name, icon, href }: NavitemProps) {
  var isActive = false;
  const { pathname } = useRouter();

  if (href !== "/") {
    const [, group] = href.split("/");
    isActive = pathname.includes(group);
  } else {
    if (href === pathname) {
      isActive = true;
    }
  }

  return (
    <Link href={href}>
      <a
        className={`${
          isActive ? "border-emerald-500" : "border-transparent"
        } p-2 font-medium border-b-2 text-gray-100  hover:border-emerald-500 flex items-center gap-1 tracking-wide `}
      >
        <>
          {icon} {name}
        </>
      </a>
    </Link>
  );
}
