// import AdsPopUp from "../ads/ads-pop-up"
import { BottomNav } from "./bottom-nav"
import { Footer } from "./footer"
import Header from "./header"

interface Props {
    children: React.ReactNode
}

export const LayoutRoot: React.FC<Props> = ({ children }) => {
    return (
        <main className="min-h-screen text-gray-800 dark:text-gray-100 bg-gray-100 dark:bg-hitam">
            <Header />
            {children}
            <BottomNav />
            {/* <AdsPopUp /> */}
            <Footer />
        </main>
    )
}
