import Image from "next/image"
import Link from "next/link"
import { useTheme } from "next-themes"
import { HiMoon, HiSun, HiChevronDown, HiSearch } from "react-icons/hi"
import { NavItem } from "./navitem"
import { useEffect, useState, Fragment } from "react"
import { useRouter } from "next/router"
import { Menu, Transition } from "@headlessui/react"

interface MenuDropProps {
    href: string
    title: string
}

interface MenuDropStyleProps {
    data: Array<MenuDropProps>
    title: string
}

const dropYearData: Array<MenuDropProps> = [
    {
        href: "/year/2023",
        title: "2023",
    },
    {
        href: "/year/2022",
        title: "2022",
    },
    {
        href: "/year/2021",
        title: "2021",
    },
    {
        href: "/year/2020",
        title: "2020",
    },
    {
        href: "/year/2019",
        title: "2019",
    },
    {
        href: "/all-year",
        title: "All Year",
    },
]

const menuLangData: Array<MenuDropProps> = [
    {
        href: "/language/english",
        title: "English",
    },
    {
        href: "/language/korean",
        title: "Korean",
    },
    {
        href: "/language/chinese",
        title: "Chinese",
    },
    {
        href: "/language/spanish",
        title: "Spanish",
    },
    {
        href: "/language/russian",
        title: "Russian",
    },
    {
        href: "/language/thai",
        title: "Thai",
    },
]

const menuGenreData: Array<MenuDropProps> = [
    {
        href: "/genre/action",
        title: "Action",
    },
    {
        href: "/genre/comedy",
        title: "Comedy",
    },
    {
        href: "/genre/drama",
        title: "Drama",
    },
    {
        href: "/genre/romance",
        title: "Romance",
    },
    {
        href: "/all-genre",
        title: "All Genre",
    },
]

export default function Header() {
    const { systemTheme, theme, setTheme } = useTheme()
    const [mounted, setMounted] = useState(false)
    const router = useRouter()

    // console.log(router.pathname)
    useEffect(() => {
        setMounted(true)
    }, [])

    function renderDarkBtn() {
        if (mounted == true) {
            const currentTheme = theme === "system" ? systemTheme : theme
            if (currentTheme === "dark") {
                return (
                    <button
                        aria-label="light-theme"
                        className="rounded-md p-2 bg-gray-600"
                        onClick={() => setTheme("light")}
                    >
                        <HiSun className="w-6 h-6 text-yellow-500" />
                    </button>
                )
            } else {
                return (
                    <button
                        aria-label="dark-theme"
                        className="rounded-md p-2 bg-gray-300"
                        onClick={() => {
                            setTheme("dark")
                        }}
                    >
                        <HiMoon className="w-6 h-6 text-slate-800" />
                    </button>
                )
            }
        }
    }

    const renderSearchBtn = () => {
        return (
            <Link href="/pencarian">
                <a
                    rel="nofollow"
                    aria-label="dark-theme"
                    title="Search Subtitle"
                    className="hover:rounded-md hover:dark:bg-pink-500 p-2 hover:bg-blue-500"
                >
                    <HiSearch className="w-6 h-6 text-white" />
                </a>
            </Link>
        )
    }

    return (
        <header
            className={`${router.pathname == "/read/[chapter]" ? "hidden" : ""
                } flex items-center w-full fixed z-40 h-16 bg-brand-blue dark:bg-brand shadow-md`}
        >
            <div className="flex items-center justify-between h-full w-full px-4 md:max-w-6xl mx-auto">
                <div className="flex flex-row items-center space-x-6">
                    <Link href="/">
                        <a className="flex gap-1 items-center mr-2">
                            <h1 className="font-semibold text-gray-100 text-xl">
                                Subscene.id
                            </h1>
                        </a>
                    </Link>
                    <div className="hidden lg:flex flex-row justify-between items-center space-x-2">
                        <NavItem href="/" name="Home" />
                        <NavItem href="/ongoing" name="Ongoing" />
                        <MenuDropStyle data={menuGenreData} title="Genre" />
                        <MenuDropStyle data={dropYearData} title="Tahun" />
                        <MenuDropStyle data={menuLangData} title="Bahasa" />
                        <NavItem
                            href="/request-subtitle"
                            name="Request/Report"
                        />
                    </div>
                </div>
                <div className="flex flex-row items-center space-x-3">
                    {renderSearchBtn()}
                    {renderDarkBtn()}
                </div>
            </div>
        </header>
    )
}

const MenuDropStyle = ({ data, title }: MenuDropStyleProps) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="border-transparent p-2 font-medium border-b-2 text-gray-100  hover:border-emerald-500 flex items-center gap-1 tracking-wide">
                    {title}
                    <HiChevronDown />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute left-0 w-36 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                        {data.map((item: MenuDropProps) => {
                            return (
                                <Menu.Item key={item.href}>
                                    <DropDownItem
                                        href={item.href}
                                        title={item.title}
                                    />
                                </Menu.Item>
                            )
                        })}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

interface DropDownItemProps {
    href: string
    title?: string
    active?: boolean
}

const DropDownItem = ({ href, title, active }: DropDownItemProps) => {
    return (
        <>
            <Link href={href}>
                <a
                    className={`hover:bg-brand-blue dark:hover:bg-brand hover:text-white text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                    {title}
                </a>
            </Link>
        </>
    )
}
