import { HiOutlineHome } from "react-icons/hi"
import { TbRefresh } from "react-icons/tb"
import { FaRegClosedCaptioning } from "react-icons/fa"
import { GoReport } from "react-icons/go"
import { createElement } from "react"
import { useRouter } from "next/router"
import Link from "next/link"
import { IconType } from "react-icons"
const bottomNavigation: BottomNavigationItem[] = [
    {
        name: "Home",
        icon: HiOutlineHome,
        href: "/",
        exact: true,
    },
    {
        name: "Ongoing",
        icon: TbRefresh,
        href: "/ongoing",
        exact: false,
    },
    {
        name: "Subtiles",
        icon: FaRegClosedCaptioning,
        href: "/allpost",
        exact: false,
    },
    {
        name: "Request",
        icon: GoReport,
        href: "/request-subtitle",
        exact: false,
    },
]

interface BottomNavigationItem {
    name: string
    icon: IconType
    href: string
    exact?: boolean
    action?: string
}

export const BottomNav = () => {
    const router = useRouter()

    function renderItem(item: any) {
        return (
            <>
                {createElement(item.icon, {
                    className: "w-5 h-5",
                    "aria-hidden": true,
                })}
                <span className="btm-nav-label truncate">{item.name}</span>
            </>
        )
    }

    function handleMenu(action: any) {}

    return (
        <div className="lg:hidden btm-nav bg-white dark:bg-hitam1 z-[20] border-t border-gray-400 dark:border-gray-800">
            {bottomNavigation.map((item) => {
                const isActive = item.exact
                    ? item.href === router.asPath
                    : router.asPath.startsWith(item.href)
                if (item.href) {
                    return (
                        <button
                            key={item.name}
                            className={`hover:text-brand-blue dark:hover:text-pink-500 ${
                                isActive
                                    ? " text-brand-blue dark:text-pink-500 dark:bg-sub-gray bg-sub-white-gray border-t-3"
                                    : ""
                            }`}
                        >
                            <Link href={item.href}>
                                <a
                                    className={`flex items-center flex-col justify-center`}
                                >
                                    {renderItem(item)}
                                </a>
                            </Link>
                        </button>
                    )
                }
            })}
        </div>
    )
}
