import "../styles/globals.css"
import "nprogress/nprogress.css"

import type { AppProps } from "next/app"
import { LayoutRoot } from "../components/layout/layout-root"
import NProgress from "nprogress"
import { ThemeProvider } from "next-themes"
import Head from "next/head"
import { useEffect } from "react"
import { DefaultSeo } from "next-seo"
import siteConfig from "../constants/site-config.json"
import Script from "next/script"
// import AdsFloatingDown from "../components/ads/floating-down"

export const progress = NProgress.configure({
    showSpinner: false,
})

const meta = {
    siteName: siteConfig.site_name,
    title: `${siteConfig.site_name} - ${siteConfig.site_tagline}`,
    tagline: siteConfig.site_tagline,
    description: siteConfig.site_description,
    url: siteConfig.site_url,
}

function MyApp({ Component, pageProps, router }: AppProps) {
    useEffect(() => {
        const handleStart = (_: string, { shallow }: { shallow: boolean }) => {
            if (!shallow) {
                progress.start()
            }
        }
        const handleStop = () => {
            progress.done()
        }

        router.events.on("routeChangeStart", handleStart)
        router.events.on("routeChangeComplete", handleStop)
        router.events.on("routeChangeError", handleStop)

        return () => {
            router.events.off("routeChangeStart", handleStart)
            router.events.off("routeChangeComplete", handleStop)
            router.events.off("routeChangeError", handleStop)
        }
    }, [router])
    return (
        <ThemeProvider enableSystem={true} attribute="class">
            <DefaultSeo
                canonical={`${meta.url}${router.asPath || "/"}`}
                description={meta.description}
                openGraph={{
                    type: "website",
                    locale: "id_ID",
                    title: meta.title,
                    description: meta.description,
                    site_name: meta.siteName,
                }}
                titleTemplate={`%s - ${meta.siteName}`}
            />
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
            </Head>
            <Script
                strategy="lazyOnload"
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
            />
            <Script strategy="lazyOnload" id="ga1">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                    page_path: window.location.pathname,
                    });
                `}
            </Script>
            <LayoutRoot>
                <Component {...pageProps} />
                {/* <AdsFloatingDown /> */}
            </LayoutRoot>
        </ThemeProvider>
    )
}

export default MyApp
